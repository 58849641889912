<template>
  <div class="flex flex-col">
    <h1 class="text-theme-sidebr text-2xl font-poppins mb-1">
      {{ $t(locale.title) }}
    </h1>
    <b-card>
      <b-card-text>
        <b-container>
          <ValidationObserver ref="form">
            <b-row>
              <b-col cols="12" lg="4">
                <div class="flex flex-col">
                  <div class="mb-1">
                    <!-- <validation-provider 
                                            #default="{errors}"
                                            :rules="'required'"
                                            :name="$t(locale.eventTitle)"
                                        > -->
                    <label for="" class="text-base">{{
                        $t(locale.eventTitle)
                      }}</label>
                    <div class="w-4/5">
                      <b-form-input
                          v-model="emailForm.event_title"
                          name="login-email"
                          :placeholder="$t(locale.eventTitle)"
                      />
                      <!-- <small
                                                    class="text-danger"
                                                    v-text="errors[0]"
                                                /> -->
                    </div>
                    <!-- </validation-provider> -->
                  </div>
                  <div class="mb-1">
                    <validation-provider
                        #default="{ errors }"
                        :rules="'required'"
                        :name="$t(locale.subject)"
                    >
                      <label for="" class="text-base">{{
                          $t(locale.subject)
                        }}</label>
                      <div class="w-4/5">
                        <b-form-input
                            name="login-email"
                            v-model="emailForm.subject"
                            :placeholder="$t(locale.subject)"
                        />
                        <small class="text-danger" v-text="errors[0]"/>
                      </div>
                    </validation-provider>
                  </div>
                  <div class="mb-1">
                    <label for="" class="text-base">{{
                        $t(locale.recipient)
                      }}</label>
                    <div class="grid grid-cols-3 gap-3 mb-1">
                      <span
                          v-for="(item, i) in selectedCategory"
                          :key="item.uuid"
                          class="flex gap-2 bg-blue-900 c-p-1 rounded-md text-white"
                      >
                        <small>{{ item.name }}</small>
                        <span class="cursor-pointer" @click="removeCategory(i)">
                          <feather-icon icon="Trash2Icon"/>
                        </span>
                      </span>
                    </div>
                    <div class="w-4/5">
                      <v-select
                          class="w-full"
                          v-model="emptyCate"
                          label="name"
                          @option:selected="setCategory($event)"
                          :filterable="false"
                          :options="memberCategory"
                          @search="searchCategories"
                      >
                        <template slot="no-options">
                          {{ $t('emailSectionCard.searchCategories') }}
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.name }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.name }}
                          </div>
                        </template>
                      </v-select>
                      <!-- <small
                                                        class="text-danger"
                                                        v-text="errors[0]"
                                                    /> -->
                    </div>
                    <!-- </validation-provider> -->
                  </div>
                  <div class="mb-1">
                    <label for="" class="text-base">{{
                        $t(locale.members)
                      }}</label>
                    <div class="grid grid-cols-3 gap-3 mb-1">
                      <span
                          v-for="(item, i) in selectedMembers"
                          :key="item.uuid"
                          class="flex gap-2 bg-blue-900 c-p-1 rounded-md text-white"
                      >
                        <small>{{
                            item.first_name + " " + item.last_name
                          }}</small>
                        <span class="cursor-pointer" @click="removeMember(i)">
                          <feather-icon icon="Trash2Icon"/>
                        </span>
                      </span>
                    </div>
                    <div class="w-4/5">
                      <v-select
                          class="w-full"
                          label="first_name"
                          v-model="emptyCate"
                          @option:selected="setMembers($event)"
                          :filterable="false"
                          :options="members"
                          @search="searchMembers"
                      >
                        <template slot="no-options">
                          {{ $t('emailSectionCard.searchMembers') }}
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.first_name + " " + option.last_name }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.first_name + " " + option.last_name }}
                          </div>
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <div></div>
                  <div></div>
                </div>
              </b-col>

              <b-col cols="12" lg="4">
                <div class="flex flex-col">
                  <div class="mb-1">
                    <!-- <validation-provider
                                            #default="{errors}"
                                            :rules="'required'"
                                            :name="$t(locale.deliverDate)"
                                        > -->
                    <label for="" class="text-base">{{
                        $t(locale.deliverDate)
                      }}</label>
                    <div class="w-1/2">
                      <date-picker
                          v-model="emailForm.deliver_at"
                          valueType="format"
                          class="w-100"
                          prefix-class="xmx"
                          :placeholder="$t(locale.deliverDate)"
                      />
                      <!-- <small
                                                class="text-danger"
                                                v-text="errors[0]"
                                            /> -->
                    </div>
                    <!-- </validation-provider> -->
                  </div>
                  <div class="mb-1">
                    <validation-provider
                        #default="{ errors }"
                        :rules="'required'"
                        :name="$t(locale.emailTemplate)"
                    >
                      <label for="" class="text-base">{{
                          $t(locale.emailTemplate)
                        }}</label>
                      <div class="w-4/5">
                        <b-form-group>
                          <v-select
                              class="w-full"
                              label="title"
                              v-model="templateVal"
                              @option:selected="setTemplate($event)"
                              :filterable="false"
                              :options="templateResults"
                              @search="searchTemplate"
                          >
                            <template slot="no-options">
                              {{ $t('emailSectionCard.searchTemplates') }}
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="d-center">
                                {{ option.title }}
                              </div>
                            </template>
                            <template
                                slot="selected-option"
                                slot-scope="option"
                            >
                              <div class="selected d-center">
                                {{ option.title }}
                              </div>
                            </template>
                          </v-select>
                          <!-- <v-select
                                                        v-model="templateVal"
                                                        :placeholder="$t(locale.emailTemplate)"
                                                        :options="templateResults"
                                                        @option:selected="computeSelect($event, 'template')"
                                                        class="resource-selector d-inline-block"
                                                    /> -->
                          <small class="text-danger" v-text="errors[0]"/>
                        </b-form-group>
                      </div>
                    </validation-provider>
                  </div>
                  <div class="mb-1">
                    <label for="" class="text-base"
                    >{{ $t(locale.testEmail) }}
                      <small>({{ $t(locale.testingOnly) }})</small></label
                    >
                    <div class="w-4/5">
                      <b-form-input
                          name="login-email"
                          v-model="emailForm.email"
                          :placeholder="$t(locale.testEmail)"
                      />
                    </div>
                  </div>
                  <router-link
                      :to="{
                      name: 'editEmailTemplate',
                      params: { uuid: emailForm.template },
                    }"
                      v-if="emailForm.template"
                      class="flex items-center md:space-x-4 mb-1 mt-1"
                  >
                    <h1 class="font-semibold text-xl text-blue-800">
                      {{ $t(locale.editEmailDesign) }}
                    </h1>
                    <div class="cursor-pointer">
                      <span>
                        <img src="@/assets/icon/pencil-edit.png" alt=""/>
                      </span>
                    </div>
                  </router-link>
                  <router-link
                      :to="{ name: 'createEmailTemplate' }"
                      class="flex items-center md:space-x-4 cursor-pointer"
                  >
                    <h1 class="font-semibold text-xl text-blue-800">
                      {{ $t(locale.createNewDesign) }}
                    </h1>
                    <div class="cursor-pointer">
                      <img src="@/assets/icon/pencil-edit.png" alt=""/>
                    </div>
                  </router-link>
                </div>
              </b-col>
              <b-col cols="12" lg="4">
                <p class="" v-text="$t(locale.memberTitle)"/>

                <ul class="mb-1 pl-2">
                  <li
                      v-for="(message, index) in messageList"
                      :key="index"
                      style="list-style-type: disc"
                      v-text="message"
                  />
                </ul>

                <p class="" v-text="$t(locale.articleFooter)"/>
              </b-col>
            </b-row>

            <b-row class="mb-4 mt-3">
              <b-col cols="12" lg="4">
                <div class="w-11/12">
                  <button
                      @click="openEmailPreview"
                      class="flex p-1 w-full text-center focus:outline-none bg-blue-900 text-white whitespace-nowrap rounded-md"
                  >
                    <!-- <span v-if="saving">
                                            <b-spinner
                                                small
                                                type="grow"
                                            />
                                            {{ $t('Message.loading') }}...
                                        </span> -->

                    <span
                        class="whitespace-nowrap text-center w-full text-base"
                        v-text="$t(locale.previewEmail)"
                    />
                  </button>
                </div>
              </b-col>
              <b-col cols="12" lg="4">
                <div class="w-11/12">
                  <button
                      :disabled="sendingTest"
                      @click="sendEmailNotification('test')"
                      type="submit"
                      class="flex p-1 w-full text-center focus:outline-none bg-theme-brown text-white whitespace-nowrap rounded-md"
                  >
                    <span class="w-full flex justify-center" v-if="sendingTest">
                      <b-spinner small type="grow"/>
                      {{ $t("Message.loading") }}...
                    </span>

                    <span
                        v-else
                        class="whitespace-nowrap text-center w-full text-base"
                        v-text="$t(locale.sendTestEmail)"
                    />
                  </button>
                </div>
              </b-col>
              <b-col cols="12" lg="4">
                <div class="w-11/12">
                  <button
                      :disabled="sendingSms"
                      @click="sendEmailNotification('send')"
                      type="submit"
                      class="flex p-1 w-full text-center focus:outline-none bg-theme-teal text-white whitespace-nowrap rounded-md"
                  >
                    <span class="w-full flex justify-center" v-if="sendingSms">
                      <b-spinner small type="grow"/>
                      {{ $t("Message.loading") }}...
                    </span>

                    <span
                        v-else
                        class="whitespace-nowrap text-center w-full text-base"
                        v-text="$t(locale.sendEmail)"
                    />
                  </button>
                </div>
              </b-col>
            </b-row>
          </ValidationObserver>
        </b-container>
      </b-card-text>

      <!-- <b-card-title v-text="$t(locale.exportLogDetail)" /> -->

      <b-row class="mt-3">
        <b-col cols="12" lg="4">
          <h1
              @click="filterForPrevious"
              class="text-theme-sidebar font-semibold text-xl cursor-pointer font-rubik"
          >
            {{ $t(locale.previousNewsletter) }}
          </h1>
        </b-col>
        <b-col cols="12" lg="4">
          <h1
              @click="filterForSchedule"
              class="text-purple-900 font-semibold text-xl cursor-pointer font-rubik"
          >
            {{ $t(locale.scheduleNewsletter) }}
          </h1>
        </b-col>
      </b-row>

      <b-card-text>
        <b-table
            responsive
            sticky-header
            show-empty
            class="scrol-area table-container"
            :items="emailResults.results"
            :fields="fields"
        >
          <template #head(eventId)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
                            <span
                                class="text-center"
                                v-text="$t(data.field.label)"
                            />
            </div>
          </template>
          <template #head(title)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="data.field.title"/>
            </div>
          </template>
          <template #head(subject)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="data.field.title"/>
            </div>
          </template>

          <template #head(deliver_at)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="data.field.title"/>
            </div>
          </template>

          <template #head(created_at)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="data.field.title"/>
            </div>
          </template>

          <template #head(status)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #head(message)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #head(action)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #cell(eventId)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="data.item.event_id"/>
            </div>
          </template>
          <template #cell(title)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="data.item.event_title"/>
            </div>
          </template>

          <template #cell(deliver_at)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span
                  class="text-center"
                  v-text="data.item.deliver_at.split('T')[0]"
              />
            </div>
          </template>

          <template #cell(created_at)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span
                  class="text-center"
                  v-text="data.item.created_at.split('T')[0]"
              />
            </div>
          </template>

          <template #cell(status)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-if="GET_LOCALE === 'sv'" v-text="data.item.status.swe_status"/>
              <span class="text-center" v-if="GET_LOCALE === 'en'" v-text="data.item.status.eng_status"/>
            </div>
          </template>

          <template #cell(message)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="data.item.message"/>

            </div>
          </template>

          <template #cell(action)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="cursor-pointer mr-0" @click="openEmail(data.item)">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-5 w-5 text-blue-800"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                >
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
                            </span>
              <span class="cursor-pointer" @click="removeEmail(data.item)">
                <feather-icon icon="Trash2Icon"/>
              </span>
            </div>
          </template>
        </b-table>

        <div
            class="d-flex align-items-end flex-sm-column justify-content-between justify-content-sm-end"
        >
          <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalProducts"
              :per-page="queryParams.limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 order-2 order-sm-1"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>
          </b-pagination>
        </div>
      </b-card-text>
    </b-card>

    <Modal id="modal-remove-email" :hideFooter="true" :title="'Remove SMS'">
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0">Är du säker på att du vill radera nyhetsbrevet?</p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-remove-email')"
        >
          <span v-text="$t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="sendingModalAction"
            @click="removeNewsletterInfo"
        >
          <span
              v-text="sendingModalAction ? $t('Message.loading') :  $t('Message.proceed')"
          />
        </b-button>
      </div>
    </Modal>

    <Modal id="modal-preview-email" :hideFooter="true" :title="'Email Preview'" :size="`lg`">
      <b-card-text class="d-flex flex-column " style="gap: 1rem">
        <div v-html="emailPreviewObj.html_content"></div>
      </b-card-text>
    </Modal>
    <Modal
        id="modal-open-email"
        :hideFooter="true"
        :hideHeader="true"
        :title="``"
        :size="'md'"
        v-if="showDetail"
    >
      <span class="absolute right-3 cursor-pointer" @click="closeDataDetail"><i class="fa fa-times"></i></span>
      <div class="mt-3">
        <h3>NYHETSBREVSUTSKICK</h3>
        <hr>
      </div>
      <div v-if="selectedData" class="mb-2">
        <p class="mb-0 pb-0 text-lg font-semibold text-sky-700 text-theme-sidebar">Sammanfattning</p>
        <p class="mb-0 pb-0 text-base">Meddelandet var menat att skickas till {{ parseArrayMessage(selectedData.members) }}
          medlem(mar)
          {{ parseFormatDate(selectedData.deliver_at) }}</p>
      </div>
      <div v-if="selectedData" class="mb-2">
        <p class="mb-0 pb-0 text-base">{{ selectedData.message }}</p>
      </div>
      <div class="w-full">
        <p class="mb-0 pb-0 text-lg font-semibold text-sky-700 text-theme-sidebar">Receivers</p>
        <p class="mb-0 pb-0 text-base"> {{
            getEmailDeliveryStats(true, selectedData)
          }}/{{ parseArrayMessage(selectedData.members) }} medlemmar mottog mejlet</p>
        <table class="table">
          <tbody>
          <tr>
            <td class="custom-tbl-td">
              <span class="font-semibold flex text-theme-sidebar pl-2.5">
                Members
              </span>
            </td>
          </tr>
          <tr v-for="(member ,i) in selectedData.members_data" :key="i">
            <td class="custom-tbl-td">
              <span class="font-semibold flex">
                <span><svg v-if="member.delivered === true"
                           xmlns="http://www.w3.org/2000/svg"
                           class="h-5 w-5 text-green-500"
                           viewBox="0 0 20 20"
                           fill="currentColor"
                >
                      <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                      />
                    </svg>
                <svg v-else
                     xmlns="http://www.w3.org/2000/svg"
                     class="h-5 w-5 text-red-500"
                     fill="none"
                     viewBox="0 0 24 24"
                     stroke="currentColor"
                     stroke-width="2"
                >
                      <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg></span>
                <span class="relative top-0.5 ml-1">{{ member.member.golf_id }} - {{ member.member.name }}</span>
              </span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </Modal>
    <Modal
        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied :message="accessDeniedMessage"/>
    </Modal>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from "vee-validate";
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import {dateMixins} from "@/mixins/dateMixins";
import debounce from "lodash.debounce";
import useAppConfig from "@core/app-config/useAppConfig";
import {mapGetters} from "vuex"
import moment from "moment";

import accessDenied from "@core/components/permission/accessDenied";

export default {
  name: "EmailSendout",
  mixins: [dateMixins],
  components: {
    vSelect,
    DatePicker,
    ValidationProvider,
    ValidationObserver, accessDenied
  },
  data() {
    return {
      config: useAppConfig(),
      accessDeniedMessage: null,
      emailForm: {
        member_categories: [],
        members: [],
        subject: "",
        template: "",
        event_title: "",
        deliver_at: "",
        email: "",
      },
      emailPreviewObj: {},
      sendingModalAction: false,
      showDetail: false,
      templateVal: "",
      emptyCate: "",
      memberCategory: [],
      members: [],
      selectedCategory: [],
      selectedMembers: [],
      templateResults: [],
      sendingTest: false,
      sendingSms: false,
      selectedData: {},
      emailResults: {results: []},
      copyEmailResults: {results: []},
      queryParams: {
        limit: 30,
      },
      pagination: {
        currentPage: 1,
        totalProducts: 0,
      },
      fields: [
        {
          key: "eventId",
          title: "Event Title",
          label: "exportCard.table.eventId",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 10px",
            width: "px",
          },
        },
        {
          key: "subject",
          title: "Ämne",
          label: "exportCard.table.fileName",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "px",
          },
        },
        {
          key: "deliver_at",
          title: "Leveransdatum",
          label: "exportCard.table.fileName",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "px",
          },
        },
        {
          key: "created_at",
          title: "Skapelsedatum",
          label: "exportCard.table.fileName",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "px",
          },
        },
        {
          key: "status",
          label: "Message.tableHeader.status",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "px",
          },
        },
        {
          key: "message",
          label: "Notification.message",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "px",
          },
        },
        {
          key: "action",
          label: "smsSectionCard.table.action",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "80px",
          },
        },
      ],
      locale: {
        upload: "upload",
        uploadInvoiceTitle: "exportCard.uploadInvoiceTitle",
        alertPermited: "exportCard.alertPermited",
        downloadExcel: "exportCard.downloadExcel",
        memberTitle: "exportCard.memberTitle",
        articleFooter: "exportCard.articleFooter",
        exportLogDetail: "exportCard.exportLogDetails",

        eventTitle: "smsSectionCard.form.eventTitle",
        recipient: "smsSectionCard.form.memberCategories",
        members: "smsSectionCard.form.members",
        subject: "emailSectionCard.form.subject",
        deliverDate: "emailSectionCard.form.deliverDate",
        emailTemplate: "emailSectionCard.form.emailTemplate",

        title: "emailSectionCard.title",
        editEmailDesign: "emailSectionCard.editEmailDesign",
        createNewDesign: "emailSectionCard.createNewDesign",
        previewEmail: "emailSectionCard.previewEmail",
        sendTestEmail: "emailSectionCard.sendTestEmail",
        sendEmail: "emailSectionCard.sendEmail",
        previousNewsletter: "emailSectionCard.previousNewsletter",
        scheduleNewsletter: "emailSectionCard.scheduleNewsletter",
        testEmail: "emailSectionCard.form.testEmail",
        testingOnly: "emailSectionCard.form.testingOnly",
        testingEmailRequired: "emailSectionCard.form.testingEmailRequired",
      },
    };
  },
  computed: {
    messageList() {
      return this.$t("exportCard.articleList");
    },
    customMsg() {
      if (this.getLang == "") {
        return this.swedishLang ? "message" : "eng_message";
      } else return this.getLang == "sv" ? "message" : "eng_message";
    },
    message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    features() {
      if (this.getLang == "") {
        return this.swedishLang ? "features" : "features";
      } else return this.getLang == "sv" ? "features" : "features";
    },
    getLang() {
      return this.config.lang.value;
    },
    locale_message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  methods: {
    closeDataDetail() {
      this.showDetail = false
      this.selectedData = {}
      this.$bvModal.hide('modal-open-email')
    },
    getEmailDeliveryStats(delivered, item) {
      if (item) {
        let data = item.members_data.filter(member => member.delivered === delivered)
        return data.length
      } else {
        return 0
      }
    },
    parseArrayMessage(msg) {
      if (msg === null || msg === undefined) {
        return 0
      }
      return msg.length
    },
    parseFormatDate(date_string) {
      if (date_string) {
        return moment(date_string).format('YYYY-MM-DD')
      } else {
        return ''
      }


    },
    // getMemberCareEmailTemplate () {
    //     this.$useJwt.memberCare('newsletter/template', 'get', {})
    //     .then(res => {
    //         this.templateResults = res.data.data.results.map(el => {
    //             return { label : el.title, value : el.uuid }
    //         })
    //     })
    // },
    searchTemplate(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteTemplate(loading, search, this);
      }
    },
    searchRemoteTemplate: debounce(function (loading, search, vm) {
      this.$useJwt
          .memberCare({method: 'get', URL: "newsletter/template?search=" + escape(search)})
          .then((res) => {
            this.templateResults = res.data.data.results;
            loading(false);
          })
          .catch((err) => {
          });
    }, 350),
    fetchTemplate: function () {
      this.$useJwt
          .memberCare({method: 'get', URL: "newsletter/template"})
          .then((res) => {
            this.templateResults = res.data.data.results;
          })
          .catch((err) => {
          });
    },
    setTemplate(item) {
      this.emailForm.template = item.uuid;
      this.emailPreviewObj = item
    },
    removeCategory(index) {
      this.selectedCategory.splice(index, 1);
    },
    removeMember(index) {
      this.selectedMembers.splice(index, 1);
    },
    searchMembers(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteMembers(loading, search, this);
      }
    },
    searchRemoteMembers: debounce(function (loading, search, vm) {
      this.$useJwt
          .getMembers({URL: "?search=" + escape(search), method: "get"})
          .then((res) => {
            this.members = res.data.data.results;
            loading(false);
          })
          .catch((err) => {
          });
    }, 350),
    fetchMember: function () {
      this.$useJwt
          .getMembers({URL: "", method: "get"})
          .then((res) => {
            this.members = res.data.data.results;
          })
          .catch((err) => {
          });
    },
    setMembers(item) {
      const uuid = this.selectedMembers.filter((el) => el.uuid === item.uuid);
      if (uuid.length < 1) {
        this.selectedMembers.push(item);
      }
      this.emptyCate = "";
    },
    searchCategories(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteMemberCate(loading, search, this);
      }
    },
    searchRemoteMemberCate: debounce(function (loading, search, vm) {
      this.$useJwt
          .getMembersCategory({URL: "?search=" + escape(search), method: "get"})
          .then((res) => {
            this.memberCategory = res.data.data.results;
            loading(false);
          })
          .catch((err) => {
          });
    }, 350),
    fetchCategory: function () {
      this.$useJwt
          .getMembersCategory({URL: "", method: "get"})
          .then((res) => {
            this.memberCategory = res.data.data.results;
          })
          .catch((err) => {
          });
    },
    setCategory(item) {
      const uuid = this.selectedCategory.filter((el) => el.uuid === item.uuid);
      if (uuid.length < 1) {
        this.selectedCategory.push(item);
      }
      this.emptyCate = "";
    },
    computeSelect(event, value) {
      this.emailForm[value] = event.value;
    },
    openEmailPreview() {
      this.$bvModal.show("modal-preview-email");
    },
    sendEmailNotification(data = null) {
      this.$refs.form.validate().then((res) => {
        if (res) {
          if (data === "test" && this.emailForm.email === "") {
            this.popupMsg(
                this.$t("Message.Failed"),
                this.$t(locale.testingEmailRequired),
                "AlertTriangleIcon",
                "danger"
            );
            return;
          }
          if (
              data === "send" &&
              (!this.emailForm.event_title || !this.emailForm.deliver_at)
          ) {
            this.popupMsg(
                this.$t("Message.Failed"),
                "Event title & date required",
                "AlertTriangleIcon",
                "danger"
            );
            return;
          }
          if (
              data === "send" &&
              !this.validatePresentDate(this.emailForm.deliver_at)
          ) {
            this.popupMsg(
                this.$t("Message.Failed"),
                "Date is in past",
                "AlertTriangleIcon",
                "danger"
            );
            return;
          }
          let url = "";
          const payload = {
            member_categories: [],
            members: [],
            subject: this.emailForm.subject,
            template: this.emailForm.template,
            event_title: this.emailForm.event_title,
            deliver_at: this.emailForm.deliver_at,
          };

          if (data === "test") {
            this.sendingTest = true;
            url = "newsletter/test";
            payload.email = this.emailForm.email;
          } else {
            url = "newsletter";
            this.sendingSms = true;
          }

          if (this.selectedCategory.length > 0) {
            const array = [];
            this.selectedCategory.map((el) => {
              array.push(el.uuid);
            });
            payload.member_categories = array;
          }

          if (this.selectedMembers.length > 0) {
            const array = [];
            this.selectedMembers.map((el) => {
              array.push(el.uuid);
            });
            payload.members = array;
          }
          this.$useJwt
              .memberCare({method: 'post', URL: url}, payload)
              .then((res) => {
                this.getMemberCareEmail();
                this.sendingTest = false;
                this.sendingSms = false;
                this.$refs.form.reset();
                this.clearForm();
                this.popupMsg(
                    this.$t("Message.Success"),
                    res.data[this.locale_message],
                    "CheckIcon",
                    "success"
                );
              })
              .catch((err) => {
                this.sendingTest = false;
                this.sendingSms = false;
                this.popupMsg(
                    this.$t("Message.Failed"),
                    err.response.data[this.locale_message],
                    "AlertTriangleIcon",
                    "danger"
                );
              });
        }
      });
    },
    filterForPrevious() {
      const filter = this.copyEmailResults.results.filter(
          (el) => this.validatePresentDate(el.deliver_at.split("T")[0]) === false
      );
      this.emailResults = {...this.emailResults, results: filter};
    },
    filterForSchedule() {
      const filter = this.copyEmailResults.results.filter(
          (el) => this.validatePresentDate(el.deliver_at.split("T")[0]) === true
      );
      this.emailResults = {...this.emailResults, results: filter};
    },
    getMemberCareEmail() {
      this.$useJwt.memberCare({method: "get", URL: 'newsletter'},).then((res) => {
        this.emailResults = res.data.data;
        this.copyEmailResults = res.data.data;
      }).catch(err => {
        if (err.response.status === 403) {
          this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true)
          this.accessDeniedMessage = err.response.data[this.locale_message]
          this.$bvModal.show("modal-access-denied");
        }
      });
    },
    removeNewsletterInfo() {
      this.sendingModalAction = true;
      let mode = {method: "delete", URL: `newsletter/${this.selectedData.uuid}`}
      this.$useJwt
          .memberCare(mode)
          .then((res) => {
            this.sendingModalAction = false;
            this.getMemberCareEmail();
            this.$bvModal.hide("modal-remove-email");
            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.message],
                "CheckIcon",
                "success"
            );
          })
          .catch((err) => {
            this.sendingModalAction = false;
            this.popupMsg(
                this.$t("Message.Failed"),
                err.response.data[this.message],
                "AlertTriangleIcon",
                "danger"
            );
          });
    },
    removeEmail(item) {
      // this.openModal = true
      this.selectedData = item;
      this.$bvModal.show("modal-remove-email");
    },
    openEmail(item) {
      this.showDetail = true
      this.selectedData = item;
      this.$bvModal.show("modal-open-email");
    },
    clearForm() {
      this.emailForm.subject = "";
      this.emailForm.member_categories = [];
      this.emailForm.members = [];
      this.emailForm.template = "";
      this.emailForm.event_title = "";
      this.emailForm.deliver_at = "";
      this.emailForm.email = "";
      this.selectedCategory = [];
      this.selectedMembers = [];
      this.templateVal = "";
    },
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    this.getMemberCareEmail();
    this.fetchCategory()
    this.fetchMember()
    this.fetchTemplate()
  },
};
</script>

<style scoped lang="scss">
.resource-selector {
  width: 100%;
}

.message-text-box {
  border: 2px solid rgba(22, 20, 159);
  border-radius: 8px;
  padding: 7px;
}

.custom-tbl-td {
  padding-left: 0px !important;
  border-top: 1px solid #fff !important;
  border-bottom: 1px solid #ebe9f1;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/datepicker.scss";
</style>
